<template>

  <div class="limiter">
    <div class="container-login100">
      <div class="wrap-login100">
        <div class="login100-form validate-form">
          <span class="login100-form-title p-b-35">
            Inicia sesión para continuar
          </span>

          <div class="alert alert-danger" role="alert" v-if="error == true">
            Credenciales invalidas!
          </div>

          <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
            <input class="input100" type="text" name="email" v-model="username" v-bind:class="{ 'focus_input': username }"
              @keyup.enter="login" />
            <span class="focus-input100"></span>
            <span class="label-input100">Usuario</span>
          </div>


          <div class="wrap-input100 validate-input" data-validate="Password is required">
            <input class="input100" type="password" name="pass" v-model="password"
              v-bind:class="{ 'focus_input': password }" @keyup.enter="login" />
            <span class="focus-input100"></span>
            <span class="label-input100">Contraseña</span>
          </div>

          <div class="container-login100-form-btn">
            <button class="login100-form-btn" @click="login">
              <span v-show="loading == false">Ingresar</span>
              <span v-show="loading == true"><i class="fas fa-spinner fa-spin fa-2x"></i></span>
            </button>
          </div>

          <div class="social-login">
            <p class="login-divider">o iniciar sesión con</p>
            <button class="microsoft-login-btn" @click="loginMicrosoft">
              <img
                src="@/assets/images/microsoft.svg"
                alt="Microsoft" class="microsoft-icon" />
              <strong>Microsoft</strong>
            </button>
          </div>

        <div class="login100-more" :style="{ backgroundImage: 'url(' + require('@/assets/images/controlt.png') + ')' }"
          style="background-image: url('images/controlt.png'); width: 70px; height: 70px; margin: 20px auto">
        </div>
      </div>

      <div class="login100-more" :style="{ backgroundImage: 'url(' + require('@/assets/images/bg-2020.jpeg') + ')' }">

        <a target="_blank" href="https://controlt.com.co/software-gestion-logistica/">
          <h2 style="margin-top: 170px; margin-right: 10px; color: #fff; font-size: 22px">Torre de Control</h2>
        </a>
        <a target="_blank" href="https://controlt.com.co/transporte-de-carga/">
          <h2 style="margin-top: 20px; margin-right: 10px; color: #fff; font-size: 22px">Analítica</h2>
        </a>
        <a target="_blank" href="https://controlt.com.co/control-de-trafico/">
          <h2 style="margin-top: 20px; margin-right: 10px; color: #fff; font-size: 22px">Control Point</h2>
        </a>
        <a target="_blank" href="https://controlt.com.co/campus-virtual/">
          <h2 style="margin-top: 20px; margin-right: 10px; color: #fff; font-size: 22px">Campus virtual</h2>
        </a>
        <a target="_blank" href="https://controlt.com.co/central-monitoreo/">
          <h2 style="margin-top: 20px; margin-right: 10px; color: #fff; font-size: 22px">BPO</h2>
        </a>
        <a target="_blank" href="https://controlt.com.co/herramienta-trazabilidad/">
          <h2 style="margin-top: 20px; margin-right: 10px; color: #fff; font-size: 22px">Visor</h2>
        </a>
        <a target="_blank" href="https://controlt.com.co/vehiculos-de-carga/">
          <h2 style="margin-top: 20px; margin-right: 10px; color: #fff; font-size: 22px">Proveeduría</h2>
        </a>
        <a target="_blank" href="https://controlt.com.co/operador-logistico/">
          <h2 style="margin-top: 20px; margin-right: 10px; color: #fff; font-size: 22px">Integraciones</h2>
        </a>

      </div>
    </div>
  </div>
  </div>

</template>

<script defer>
import { AUTH_REQUEST } from '../store/actions/auth';
export default {
  name: "Login",
  data() {
    return {
      error: false,
      loading: false,
      username: '',
      password: ''
    }
  },
  methods: {
    login: function () {
      var self = this;
      self.loading = true;
      const { username, password } = this;
      this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
        self.loading = false;
        this.$router.push('/');
      }).catch(function () {
        self.loading = false;
        self.error = true;
      });
    },
    loginMicrosoft: function () {      
      const clientId = process.env.VUE_APP_COGNITO_CLIENTID;
      const redirectUri = process.env.VUE_APP_REDIRECT_URI;
      const cognitoDomain = process.env.VUE_APP_COGNITO_DOMAIN;

      const AzureIdp = `${cognitoDomain}/oauth2/authorize?identity_provider=AzureIdp&response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=openid+email`;

      window.location.href = AzureIdp;

    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@font-face {
  font-family: Poppins-Regular;
  src: url('../assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins-Medium;
  src: url('../assets/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../assets/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('../assets/fonts/poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../assets/fonts/montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('../assets/fonts/montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: Montserrat-Regular;
  src: url('../assets/fonts/montserrat/Montserrat-Regular.ttf');
}

body,
html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
}

/*---------------------------------------------*/
a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: underline;
  color: #fff;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}


h2 {
  font-family: Poppins-Regular, sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-left: 480px !important;
}

p {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}


/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #999999;
}

input:-moz-placeholder {
  color: #999999;
}

input::-moz-placeholder {
  color: #999999;
}

input:-ms-input-placeholder {
  color: #999999;
}

textarea::-webkit-input-placeholder {
  color: #999999;
}

textarea:-moz-placeholder {
  color: #999999;
}

textarea::-moz-placeholder {
  color: #999999;
}

textarea:-ms-input-placeholder {
  color: #999999;
}


label {
  display: block;
  margin: 0;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ utility ]*/

/*==================================================================
[ Text ]*/
.txt1 {
  font-family: Montserrat-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #555555;
}

.txt2 {
  font-family: Montserrat-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #999999;
}


/*==================================================================
[ Size ]*/
.size1 {
  width: 355px;
  max-width: 100%;
}

.size2 {
  width: calc(100% - 43px);
}

/*==================================================================
[ Background ]*/
.bg1 {
  background: #3b5998;
}

.bg2 {
  background: #1da1f2;
}

.bg3 {
  background: #cd201f;
}


/*//////////////////////////////////////////////////////////////////
[ login ]*/
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgb(255, 254, 255) 0%, rgb(89, 86, 141) 100%);
}


.wrap-login100 {
  height: 560px;
  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
  margin: 50px;
  box-shadow: -4px 9px 12px -1px rgba(0, 0, 0, 0.75);
}

/*==================================================================
[ login more ]*/
.login100-more {
  width: calc(100% - 560px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.login100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  *background: rgba(0, 0, 0, 0.1);
}



/*==================================================================
[ Form ]*/

.login100-form {
  width: 560px;
  height: 100%;
  display: block;
  background-color: #f7f7f7;
  padding: 40px 55px 55px 55px;
}

.login100-form-title {
  width: 100%;
  display: block;
  font-family: Poppins-Regular;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}



/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 80px;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-bottom: 10px;
}

.label-input100 {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #999999;
  line-height: 1.2;

  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  padding-left: 24px;
  left: 0;
  top: 30px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #555555;
  line-height: 1.2;
  padding: 0 26px;
}

input.input100 {
  height: 100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

/*---------------------------------------------*/

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #6675df;
  border-radius: 10px;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus+.focus-input100 {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.eff-focus-selection {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.focus_input:not(focus)+.focus-input100+.label-input100 {
  top: 5px;
  font-size: 13px;
}

.input100:focus {
  height: 48px;
}

.input100:focus+.focus-input100+.label-input100 {
  top: 14px;
  font-size: 13px;
}

.has-val {
  height: 48px !important;
}

.has-val+.focus-input100+.label-input100 {
  top: 14px;
  font-size: 13px;
}

/*==================================================================
[ Restyle Checkbox ]*/

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #999999;
  line-height: 1.4;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  color: transparent;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #6675df;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked+.label-checkbox100::before {
  color: #6675df;
}


/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: #EC971E;

  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #333333;
}



/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 992px) {
  .login100-form {
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .login100-more {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .login100-form {
    width: 100%;
  }

  .login100-more {
    display: none;
  }
}

@media (max-width: 576px) {
  .login100-form {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
  }
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  z-index: 100;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;

  font-family: Poppins-Regular;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  z-index: 110;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}



/*==================================================================
[ Social ]*/
.login100-form-social-item {
  width: 36px;
  height: 36px;
  font-size: 18px;
  color: #fff;
  border-radius: 50%;
}

.login100-form-social-item:hover {
  background: #333333;
  color: #fff;
}

.social-login {
  text-align: center;
  margin-top: 10px;
}

.login-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 14px;
  margin: 20px 0;
  position: relative;
}

.login-divider::before,
.login-divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: #ccc;
  margin: 0 10px;
}

.microsoft-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: none;
  background-color: #4285f4;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px auto;
  width: 200px;
}

.microsoft-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.microsoft-login-btn:hover {
  background-color: #357ae8;
}
</style>
